<template>
    <div style=" margin: -15px -15px;overflow: hidden">
      <a-spin :spinning="spinning">
      <div class="liveMeet-top">
        <div class="liveMeet-top-input">
          <div>状态:</div>
          <a-radio-group
              style="padding-left: 10px"
              v-model="radioValue"
              @change="onChange">
            <a-radio-button value="">
              全部
            </a-radio-button>
            <a-radio-button value="1">
              已结束
            </a-radio-button>
            <a-radio-button value="0">
              未结束
            </a-radio-button>
          </a-radio-group>
        </div>
        <div class="liveMeet-top-input">
          <div>标题:</div>
          <a-input
              v-model="titleData"
              class="input"
              placeholder="请输入" />
        </div>
        <div class="liveMeet-top-input">
          <a-button
              @click="seachBtn"
              type="primary">查询</a-button>
          <a-button
              @click="resetBtn"
              style="margin-left: 10px">重置</a-button>
        </div>
      </div>
      <div class="activity-table">
        <div class="activity-table-btn">
          <a-button
              @click="addBtn"
              type="primary">
            新增
          </a-button>
        </div>
        <a-table
            style="width: 99%"
            :rowKey="record=>record.id"
            :pagination="false"
            :columns="columnsAdd"
            :data-source="dataAdd">
          <span slot="prizeTotal" slot-scope="text, record">
            <a @click="drawBtn(record.id,record)">{{record.prizeTotal}}/{{record.prizeRemain}}</a>
          </span>
          <span slot="liveMeet" slot-scope="text, record">
            <span v-for="item in text">
              {{item.title}}
            </span>
          </span>
    <!--      开启-->
          <span slot="is_Open" slot-scope="text, record">
            <a-switch
                v-model="record.isOpen!==0"
                checked-children="开"
                un-checked-children="关"
                @click="switchOpen(record.id,record.isOpen === 1? 0 : 1,record)"
                default-checked />
          </span>
    
          <span slot="isFinish" slot-scope="text, record">
            <a-switch
                v-model="record.isFinish!==0"
                checked-children="开"
                un-checked-children="关"
                @click="switchFinish(record.id,record.isFinish === 1? 0 : 1,record)"
                default-checked />
          </span>
    
          <span slot="system" slot-scope="text, record">
    <!--        设置抽奖   修改   删除-->
            <a @click="iotteryBtn(record)">设置抽奖</a>
            <a style="margin-left: 10px"  @click="restBtn(record)">修改</a>
    
            <a-popconfirm
                title="是否确定删除?"
                ok-text="是"
                cancel-text="否"
                @confirm="confirm(record.id)"
                @cancel="cancel"
            >
        <a style="margin-left: 10px">删除</a>
      </a-popconfirm>
          </span>
        </a-table>
        <div style="width: 100%;height: 50px;">
          <div style="float: right;margin-right: 20px;margin-top: 15px">
            <a-pagination
                :current="page_no"
                @change="paginationBtn"
                :total="tableCount" />
          </div>
        </div>
      </div>
    <!-------------------对话框----------------->
    <!--  新增-->
      <a-drawer
          title="新增"
          placement="right"
          :closable="false"
          :visible="addVisible"
          width="600px"
          @close="()=>{this.addVisible = false
          //  清空
                this.$refs.ruleForm_add.resetFields();
                this.liveRoom = [undefined]
                this.liveRoomList = []
                this.addForm.type = 1
                this.live_page_no=1
                this.doctor_page_no=1
                this.LiveConferenceList = []
          }"
      >
        <a-form-model
            ref="ruleForm_add"
            :model="addForm"
            :rules="addRules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="标题" prop="title">
            <a-input placeholder="请输入" v-model="addForm.title"/>
          </a-form-model-item>
          <a-form-model-item label="奖品数量" prop="prize_total">
            <a-input placeholder="请输入" v-model.number="addForm.prize_total"/>
          </a-form-model-item>
          <a-form-model-item label="类型" prop="type">
            <a-radio-group v-model="addForm.type" @change="radio_group_onChange">
              <a-radio :value="1">
                直播会议
              </a-radio>
              <a-radio :value="2">
                医学会议（九宫格会议）
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="选择会议" prop="live">
            <a-select
                @popupScroll="popupScroll_live"
                @search="select_live"
                show-search
                option-filter-prop="children"
                placeholder="请选择"
                v-model="addForm.live"
                style="width: 325px">
              <a-select-option
                  @click="liveChange(selectLive.id)"
                  v-for="(selectLive,index) in LiveConferenceList" :value="selectLive.id">
                {{selectLive.title}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="直播间" prop="stream_ids">
            <div
                style="display: flex; align-items: center ;flex-wrap: wrap;width: 400px "
                v-for="(item,index) in liveRoom"
                :key="index">
              <div>
                <a-select
                    placeholder="请选择"
                    show-search
                    option-filter-prop="children"
                    v-model="liveRoom[index]"
                    style="width: 300px"
                    @change="AddChange">
                  <a-select-option
                      v-for="(live,index) in liveRoomList"
                      :value="live.id">
                    {{ live.title }}
                  </a-select-option>
                </a-select>
              </div>
              <div v-if="index!==0">
                <a-button
                    type="dashed"
                    @click="deleteAdd(item, index)"
                    style=" color:#CCCCCC; border: #CCCCCC 2px solid;margin-left: 20px;" shape="circle" size="small" >
                  <a-icon type="minus" />
                </a-button>
              </div>
              <div v-if=" index <=0" >
                <a-button
                    @click="AddBtnIcon(index)"
                    style=" color:#0099FF; border: #0099FF 2px solid;margin-left: 20px;" size="small" shape="circle">
                  <a-icon type="plus" />
                </a-button>
              </div>
            </div>
          </a-form-model-item>
        </a-form-model>
        <div
            :style="{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }"
        >
          <a-button style="marginRight: 8px" @click="()=>{this.addVisible = false
          //  清空
                this.$refs.ruleForm_add.resetFields();
                this.liveRoom = [undefined]
                this.liveRoomList = []
                this.addForm.type = 1
                this.live_page_no=1
                this.doctor_page_no=1
                this.LiveConferenceList = []
          }">
            取消
          </a-button>
          <a-button type="primary" @click="addSubmit">
            确认
          </a-button>
        </div>
      </a-drawer>
    <!--  编辑-->
      <a-drawer
          title="编辑"
          placement="right"
          :closable="false"
          :visible="restVisible"
          width="600px"
          @close="()=>{this.restVisible = false
          let form = this.restForm
          //  清空
            form.live = undefined
            form.title = ''
            form.prize_total = ''
            form.type = 1
            this.liveRestRoom = [undefined]
            this.live_page_no=1
            this.doctor_page_no=1
            this.LiveConferenceList = []
          }"
      >
        <a-form-model
            ref="ruleForm"
            :model="restForm"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="标题">
            <a-input v-model="restForm.title" placeholder="请输入"/>
          </a-form-model-item>
          <a-form-model-item label="奖品数量">
            <a-input v-model.number="restForm.prize_total" placeholder="请输入"/>
          </a-form-model-item>
          <a-form-model-item label="类型">
            <a-radio-group v-model="restForm.type" @change="radio_group_onChange_rest">
              <a-radio :value="1">
                直播会议
              </a-radio>
              <a-radio :value="2">
                医学会议（九宫格会议）
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="选择会议">
            <a-select
                @popupScroll="popupScroll_live_rest"
                @search="select_live_rest"
                show-search
                option-filter-prop="children"
                placeholder="请选择"
                v-model="restForm.live"
                style="width: 325px">
              <a-select-option
                  @click="liveChange_rest(selectLive.id)"
                  v-for="(selectLive,index) in LiveConferenceList" :value="selectLive.id">
                {{selectLive.title}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="直播间">
            <div
                style="display: flex; align-items: center ;flex-wrap: wrap;width: 400px "
                v-for="(item,index) in liveRestRoom"
                :key="index">
              <div>
                <a-select
                    placeholder="请选择"
                    v-model="liveRestRoom[index]"
                    style="width: 300px"
                    show-search
                    option-filter-prop="children"
                    @change="selectAddChange">
                  <a-select-option
                      v-for="(live,index) in liveRoomList"
                       v-model="live.id">
                    {{ live.title }}
                  </a-select-option>
                </a-select>
              </div>
              <div v-if="index!==0">
                <a-button
                    type="dashed"
                    @click="deleteLiveIndex(item, index)"
                    style=" color:#CCCCCC; border: #CCCCCC 2px solid;margin-left: 20px;" shape="circle" size="small" >
                  <a-icon type="minus" />
                </a-button>
              </div>
              <div v-if=" index <=0" >
                <a-button
                    @click="clickAddLiveTime(index)"
                    style=" color:#0099FF; border: #0099FF 2px solid;margin-left: 20px;" size="small" shape="circle">
                  <a-icon type="plus" />
                </a-button>
              </div>
            </div>
          </a-form-model-item>
        </a-form-model>
        <div
            :style="{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }"
        >
          <a-button style="marginRight: 8px" @click="()=>{this.restVisible = false
          let form = this.restForm
          //  清空
            form.live = undefined
            form.title = ''
            form.prize_total = ''
            form.type = 1
            this.live_page_no=1
            this.doctor_page_no=1
            this.LiveConferenceList = []
            this.liveRestRoom = [undefined]
          }">
            取消
          </a-button>
          <a-button type="primary" @click="restSubmit">
            确认
          </a-button>
        </div>
      </a-drawer>
        <a-modal
            title=""
            placement="right"
            :closable="false"
            :visible="visible"
            width="600px"
            @close="closeVis"
            :footer="null"
        >
          <p style="height: 100px">
            <a-icon style="color: red;font-size: 25px;margin-right: 10px" type="close-circle" />
            直播间登录方式必须是【手机号验证码登录】，且完善身份信息为【是】，才可开启抽奖活动</p>
          <div
              :style="{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }"
          >
            <a-button type="primary" @click="closeVis">
              知道了
            </a-button>
          </div>
        </a-modal>
      </a-spin>
    </div>
    </template>
    
    <script>
    import {
      delActivityList,
      postActivityList, putActivityList,
    } from "../../service/liveMeet_api2";
    import {getSetLiveList, getActivityList, getLiveList} from "../../service/liveMeet_api";
    import {CaseLive, getLiveBroadcast} from "../../service/MedicalConference";
    
    const columnsAdd = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: "5%"
      },{
        title: '标题',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: '关联会议室',
        dataIndex: 'liveMeet',
        key: 'liveMeet',
        width:'20%',
        scopedSlots: { customRender: 'liveMeet' },
      },
      {
        title: '奖品总量/剩余',
        dataIndex: 'prizeTotal',
        key: 'prizeTotal',
        ellipsis: true,
        scopedSlots: { customRender: 'prizeTotal' },
      },
      {
        title: '是否开启',
        dataIndex: 'isOpen',
        key: 'isOpen',
        ellipsis: true,
        scopedSlots: { customRender: 'is_Open' },
      },
      {
        title: '是否结束',
        dataIndex: 'isFinish',
        key: 'isFinish',
        ellipsis: true,
        scopedSlots: { customRender: 'isFinish' },
      },
      {
        title: '操作',
        dataIndex: 'system',
        key: 'system',
        ellipsis: true,
        scopedSlots: { customRender: 'system' },
      },
    ];
    
    const dataAdd = [
    ];
    export default {
      name: "Activity",
      data(){
        return{
          isOpenSwitch:true,
          live_page_no:1,
          doctor_page_no:1,
          selectTitle:'',
          LiveConferenceList:[],
          liveId:'',
          LiveConferenceList_room:[],
          visible:false,
          liveRoomList:[],
          spinning:false,
          page_no:1,
          page_size:10,
          tableCount:null,
          radioValue:'',
          titleData:'',
          addVisible:false,
          restVisible:false,
          dataAdd,
          columnsAdd,
          isShow:1,
          labelCol: { span: 4 },
          wrapperCol: { span: 14 },
          addForm:{
            title:'',
            prize_total:'',
            type:1,
            live:undefined,
            stream_ids:[undefined,],
          },
          addRules:{
            title: [{ required: true, message: '请输入', trigger: 'blur' }],
            prize_total: [{ required: true, message: '请输入', trigger: 'blur' }],
            type: [{ required: true, message: '请选择', trigger: 'change' }],
            live: [{ required: true, message: '请选择', trigger: 'change' }],
            stream_ids: [{ required: true, message: '请选择', trigger: 'blur' }],
          },
          liveRoom:[undefined,],
          liveRestRoom:[undefined,],
          restForm:{
            title:'',
            prize_total:'',
            live:undefined,
            type:1,
            stream_ids:[undefined,],
          },
    
          listId:'',
        }
      },
      created() {
        this.getTableList()
        this.$store.dispatch('setManageHeaderTitle',"直播抽奖管理")
        this.Live_Conference_List()
      },
      methods:{
        //获取会议列表
        // async getMeetList() {
        //   this.spinning = true
        //   let data = {
        //     page_size:9999
        //   }
        //   const response = await getSetLiveList(data)
        //   if (response.code === 0) {
        //     this.liveRoomList = response.data.rows
        //   } else {
        //     this.$message.warning("出错，请联系管理员", response.message)
        //   }
        //   this.spinning = false
        // },
        //获取列表
        async getTableList(page_no,page_size,title,isFinish) {
          if(page_no === ''){
            page_no = 1
          }if(page_size === ''){
            page_size = 10
          }
          this.spinning = true
          const response = await getActivityList(page_no,page_size,title,isFinish)
          if(response.code === 0){
            this.dataAdd = response.data.rows
            this.tableCount = response.data.count
            console.log(response.data)
          }else {
            this.$message.warning("出错，请联系管理员",response.message)
          }
          this.spinning = false
        },
        paginationBtn(page_no,page_size){
          this.page_no = page_no
          this.page_size = page_size
          this.getTableList(page_no,page_size)
        },
        onChange(e){
          this.page_no = 1
          this.getTableList(this.page_no,this.page_size,this.titleData,this.radioValue)
        },
        seachBtn(){
          this.getTableList(this.page_no,this.page_size,this.titleData,this.radioValue)
        },
        resetBtn(){
          this.titleData = ''
          this.radioValue = ''
          this.page_no = 1
          this.getTableList(this.page_no,this.page_size,this.titleData,this.radioValue)
        },
        getSwitchVal(isShow,id,row){
          // 判断接口中的会议列表
          for (let i in row.liveMeet) {
            if (row.liveMeet[i].loginType === 'phone_code' && row.liveMeet[i].isNeedPerfectInfo === 1 || isShow === 0) {
              this.isOpenSwitch = true
            } else {
              this.isOpenSwitch = false
              break
            }
          }
        },
        async switchOpen(id, is_open,row) {
          await this.getSwitchVal(is_open,id,row)
          if(this.isOpenSwitch === true && (is_open===0 || is_open===1)) {
            const data = {
              "stream_ids": row.streamIds.join(";"),
              "is_open": is_open
            }
            const response = await putActivityList(id, data)
            if (response.code === 0) {
              this.$message.success("修改成功！")
              await this.getTableList(this.page_no, this.page_size, this.titleData, this.radioValue)
            } else {
              this.$message.warning("出错，请联系管理员", response.message)
            }
          }else {
            //打开警告弹窗
            if (is_open === 1) {
              this.visible = true
            }
          }
        },
        async switchFinish(id,is_finish,row) {
          const data = {
            "stream_ids":row.streamIds.join(";"),
            "is_finish":is_finish
          }
          const response = await putActivityList(id,data)
          if (response.code === 0) {
            this.$message.success("修改成功！")
            await this.getTableList(this.page_no,this.page_size,this.titleData,this.radioValue)
          } else {
            this.$message.warning("出错，请联系管理员", response.message)
          }
        },
        //新增对话框的确认按钮
        addSubmit() {
          this.$refs.ruleForm_add.validate(async valid => {
            if (valid) {
              let form = this.addForm
              let data = {
                stream_ids: this.liveRoom.join(";"),
                title: form.title,
                prize_total: form.prize_total
              }
              const response = await postActivityList(data)
              if (response.code === 0) {
                this.$message.success("添加成功！")
                this.addVisible = false
                await this.getTableList()
                //  清空
                this.$refs.ruleForm_add.resetFields();
                this.liveRoom = [undefined]
                this.liveRoomList = []
                this.addForm.type = 1
                this.live_page_no=1
                this.doctor_page_no=1
                this.LiveConferenceList = []
              } else {
                this.$message.warning(response.message)
              }
            } else {
              this.$message.warning("有空~~")
              return false;
            }
          });
    
        },
        //修改对话框的确认按钮
        async restSubmit() {
          let form = this.restForm
          let data = {
              stream_ids: this.liveRestRoom.join(";"),
              title: form.title,
              prize_total: form.prize_total
          }
          this.restForm.stream_ids = this.liveRestRoom.join(";")
          const response = await putActivityList(this.listId,data)
          if (response.code === 0) {
            this.$message.success("修改成功！")
            this.restVisible = false
            await this.getTableList()
            //  清空
            form.live = undefined
            form.title = ''
            form.prize_total = ''
            form.type = 1
            this.live_page_no=1
            this.doctor_page_no=1
            this.LiveConferenceList = []
            this.liveRestRoom = [undefined]
          } else {
            this.$message.warning(response.message)
          }
        },
        liveChange(id){
          this.liveId = id
          let type = this.addForm.type
          this.liveRoom = [undefined]
          this.liveRoomList = []
          if(type === 1){
            this.Live_Conference_room_List()
          }
          if(type === 2){
            this.getCaseLiveList_room_list()
          }
        },
        liveChange_rest(id){
          this.liveId = id
          let type = this.restForm.type
          this.liveRestRoom = [undefined]
          this.liveRoomList = []
          if(type === 1){
            this.Live_Conference_room_List()
          }
          if(type === 2){
            this.getCaseLiveList_room_list()
          }
        },
        restBtn(row){
          this.Live_Conference_room_List()
          this.listId = row.id
          let form = this.restForm
          form.title = row.title
          form.prize_total = row.prizeTotal
          this.liveRestRoom = row.streamIds
          if(row.liveMeet.length !== 0){
            form.live = row.liveMeet[0].liveMettingId
            this.liveId = form.live
          }
          this.restVisible = true
        },
        selectAddChange(value){
          // console.log(value)
        },
        deleteLiveIndex(item, index){
          this.liveRestRoom.splice(index,1)
        },
        clickAddLiveTime(index){
          let ifTrue = true
          this.liveRestRoom.forEach(item => {
            if (item[index] === undefined && item[index] === null) {
              this.$message.warning('请选择直播间！')
              ifTrue = false
            }
          })
          if (ifTrue) {
            this.liveRestRoom.push("")
          }
        },
        AddChange(value){
          // console.log(value)
        },
        deleteAdd(item, index){
          this.liveRoom.splice(index,1)
        },
        AddBtnIcon(index){
          let ifTrue = true
          this.liveRoom.forEach(item => {
            if (item[index] === undefined && item[index] === null) {
              this.$message.warning('请选择直播间！')
              ifTrue = false
            }
          })
          if (ifTrue) {
            this.liveRoom.push("")
          }
        },
        iotteryBtn(row){
          this.$store.commit("liveTitle",row.title)
          if(row.liveMeet[0]){
            this.$store.commit("liveMeetName",row.liveMeet[0].title)
          }
          this.$router.push({path:'/liveiottery',query:{id:row.id}})
        },
        drawBtn(id,row){
          this.$store.commit("liveTitle",row.title)
          if(row.liveMeet[0]){
            this.$store.commit("liveMeetName",row.liveMeet[0].title)
          }
          this.$router.push({path:'winners',query:{id:id}})
        },
        async confirm(id) {
          const response = await delActivityList(id)
          if (response.code === 0) {
            this.$message.success("删除成功！")
            await this.getTableList(this.page_no, this.page_size,this.titleData,this.radioValue)
          } else {
            this.$message.warning("出错，请联系管理员", response.message)
          }
        },
        async closeVis() {
          this.visible = false
          await this.getTableList(this.page_no, this.page_size,this.titleData,this.radioValue)
        },
        cancel() {
        },
        radio_group_onChange(e){
          this.live_page_no=1
          this.doctor_page_no=1
          this.LiveConferenceList = []
          this.addForm.type = e.target.value
          if(e.target.value === 1){
            this.Live_Conference_List()
          }if(e.target.value === 2){
            this.getCaseLiveList()
          }
        //  清空选中的会议id
          this.liveId = ''
          this.addForm.live = undefined
          this.liveRoom = [undefined]
          this.liveRoomList = []
        },
        radio_group_onChange_rest(e){
          this.live_page_no=1
          this.doctor_page_no=1
          this.LiveConferenceList = []
          this.restForm.type = e.target.value
          if(e.target.value === 1){
            this.Live_Conference_List()
          }if(e.target.value === 2){
            this.getCaseLiveList()
          }
        //  清空选中的会议id
          this.liveId = ''
          this.restForm.live = undefined
          this.liveRestRoom = [undefined]
          this.liveRoomList = []
        },
        //新增对话框
        addBtn(){
          this.addVisible = true
          // this.getMeetList()
          this.Live_Conference_List()
        },
        //滚动条监听
        popupScroll_live(e){
          const {target} = e
          const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
          const clientHeight = target.clientHeight //滑动最低高度
          if(scrollHeight < clientHeight + 2){
            this.live_page_no++
            this.doctor_page_no++
            let type = this.addForm.type
            if(type === 1){
              this.Live_Conference_List(this.live_page_no,this.selectTitle)
            }if(type === 2){
              this.getCaseLiveList(this.doctor_page_no,this.selectTitle)
            }
          }
        },
        //鼠标搜索
        select_live(value){
          this.LiveConferenceList = []
          this.selectTitle = value
    
          let type = this.addForm.type
          if(type === 1){
            this.Live_Conference_List('',value)
          }if(type === 2){
            this.getCaseLiveList('',value)
          }
        },
        //滚动条监听
        popupScroll_live_rest(e){
          const {target} = e
          const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
          const clientHeight = target.clientHeight //滑动最低高度
          if(scrollHeight < clientHeight + 2){
            this.live_page_no++
            this.doctor_page_no++
            let type = this.restForm.type
            if(type === 1){
              this.Live_Conference_List(this.live_page_no,this.selectTitle)
            }if(type === 2){
              this.getCaseLiveList(this.doctor_page_no,this.selectTitle)
            }
          }
        },
        //鼠标搜索
        select_live_rest(value){
          this.LiveConferenceList = []
          this.selectTitle = value
    
          let type = this.restForm.type
          if(type === 1){
            this.Live_Conference_List('',value)
          }if(type === 2){
            this.getCaseLiveList('',value)
          }
        },
        //获取直播会议列表
        async Live_Conference_List(page_no,value) {
          const response = await getLiveList(page_no,10,value)
          if(response.code === 0){
            // this.LiveConferenceList = response.data.rows
            let list  = response.data.rows
            for (let i=0;i<list.length;i++){
              this.LiveConferenceList.push({
                id: list[i].id,
                title: list[i].title,
              })
            }
          }else {
            this.$message.warning("失败，请联系管理员"+response.message)
          }
        },
    
        //获取医学会议列表
        async getCaseLiveList(page_no,value) {
          const response = await CaseLive(page_no,10,'',value)
          if(response.code === 0){
            // this.LiveConferenceList = response.data.rows
            let list  = response.data.rows
            for (let i=0;i<list.length;i++){
              this.LiveConferenceList.push({
                id: list[i].id,
                title: list[i].title,
              })
            }
          }else {
            this.$message.warning("失败!",response.message)
          }
        },
    
        // 获取直播间列表
        async Live_Conference_room_List() {
          //直播会议列表
          let live_metting_id =  this.liveId
          let data = {
            liveMettingId:live_metting_id,
            page_size:9999,
          }
          const response = await getSetLiveList(data)
          if(response.code === 0){
            this.liveRoomList = response.data.rows
          }else {
            this.$message.warning("出错，请联系管理员！"+response.message)
          }
        },
    
        //获取医学会议直播间列表
        async getCaseLiveList_room_list() {
          //医学会议列表id
          let conventionId = this.liveId
          const response = await getLiveBroadcast(conventionId,'',999)
          if (response.code === 0) {
            this.liveRoomList = response.data.rows
          } else {
            this.$message.warning("失败"+response.message)
          }
        },
      }
    }
    </script>
    
    <style scoped lang="scss">
    .liveMeet-top{
      width: 100%;
      height: 85px;
      border-bottom: 15px solid #f0f2f5;
      display: flex;
      justify-content: space-between;
    .liveMeet-top-input{
      display: flex;
      justify-content: center;
      width: 30%;
      height: 50px;
      line-height: 30px;
      padding-top: 20px;
    .input{
      width: 70%;
      margin-left: 10px;
    }
    }
    }
    .activity-table{
      width: 100%;
      min-height: 600px;
      .activity-table-btn{
        width: 100%;
        margin-left: 20px;
        margin-top: 20px;
        margin-bottom: 15px;
      }
    }
    </style>